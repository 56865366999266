import { graphql } from 'gatsby';
import React from 'react';
import App from '../components/App/App';
import { ApolloProvider } from '@apollo/client';
import { client } from '../apollo/client';
import SEO from "../components/seo"

const IndexPage = ({ data: { gv }, pageContext }) => (
    <ApolloProvider client={client}>
        <SEO context={{...pageContext, canonicalUrlPath:''}} />
        <App btc={gv} context={{...pageContext, origin: 'home', makerSelected: null, cameraSelected: null, lensSelected: null}} />
    </ApolloProvider>
)
export default IndexPage

// Should get rid of this, unless goal is to use it to feed select boxes...? Probably not worth it only for home page.
export const query = graphql`
  query {
    gv {
      makes { _id Make }
      cameras { _id CameraModel CameraModelShort CameraModelAlts CameraModelFriendly Brand EffectivePixels AnnouncedDate SensorSize min_res max_res min_iso max_iso photo_count }
      lenses { _id LensModel Brand AnnouncedDate Weight photo_count }
    }
  }
`
